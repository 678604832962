<template>
  <div class="sb_knowledge-base">
    <sb-spacer height="40" />
    <sb-menu-tabs :use-paths="true" :routes="routes" :current-route="$route" />
    <component :is="component"></component>
  </div>
</template>

<script>
import SbMenuTabs from '@/components/SbMenuTabs';
import Methods from '@/views/Default/Methods.vue';
import Article from '@/views/Default/Article.vue';
import Articles from '@/graphql/queries/Articles.gql';

export default {
  components: {
    SbMenuTabs,
    Methods,
    Article,
  },
  data() {
    return {
      Methods,
      Article,
    };
  },
  apollo: {
    articles: {
      query: Articles,
    },
  },
  computed: {
    component() {
      if (this.$route.path.endsWith('techniques')) return Methods;
      return Article;
    },
    routes() {
      return [
        {
          path: 'techniques',
          name: 'ResourceKnowledgeBaseMethods',
          component: Methods,
          meta: {
            title: 'Technieken',
            showFor: [
              {
                role: 'COACH',
              },
            ],
          },
        },
        ...(this.articles
          ? this.articles.edges.map(({ node }) => {
            return {
              path: `article-${node.id}`,
              name: `ResourceKnowledgeBase${node.slug}`,
              component: Article,
              meta: {
                title: node.title,
              },
            };
          })
          : []),
      ];
    },
  },
};
</script>

<style lang="scss">
.sb_knowledge-base {

  ul,
  ol {
    padding-inline-start: 1.8rem;
    margin-bottom: 1rem;
  }
}
</style>
