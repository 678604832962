import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';

export const IntakeType = Object.freeze({
  AVI: 'AVI',
  DMT: 'DMT',
  MISC: 'MISC',
});

/**
 * Get a human readable label for the corresponding `IntakeType` member.
 * @param {string} type
 * @returns {string} problem type label
 */
export function getIntakeTypeLabel(type) {
  if (!IntakeType[type]) {
    throw new Error(`Unknown IntakeType member \`${type}\``);
  }

  switch (type) {
    case IntakeType.MISC:
      return 'Overig';
    default:
      return type;
  }
}

export const intakeTypeMixin = {
  mixins: [nonReactiveMembersMixin(() => ({ IntakeType }))],
  methods: { getIntakeTypeLabel },
};
