<template>
  <div class="sb_view_article">
    <sb-loading v-if="$apollo.queries.getArticleById.loading" />

    <div v-if="sections">
      <sb-spacer height="40" />
      <h1>{{ title }}</h1>

      <sb-spacer height="20" />

      <div v-for="(section, i) in sections" :key="`section-${i}`">
        <sb-section-row
          v-if="section.content.__typename === 'ArticleSectionContentRow'"
          :section="section"
        />
        <sb-section-spacer
          v-if="section.content.__typename === 'ArticleSectionContentSpacer'"
          :section="section"
        />
        <sb-section-heading
          v-if="section.content.__typename === 'ArticleSectionContentHeading'"
          :section="section"
        />
        <sb-section-list-links
          v-if="section.content.__typename === 'ArticleSectionContentListLinks'"
          :section="section"
        />
        <sb-section-faq
          v-if="section.content.__typename === 'ArticleSectionContentFaq'"
          :section="section"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GetArticleById from '@/graphql/queries/GetArticleById.gql';

import SbLoading from '@/components/SbLoading.vue';
import SbSectionRow from '@/components/SbSections/SbSectionRow';
import SbSectionSpacer from '@/components/SbSections/SbSectionSpacer';
import SbSectionHeading from '@/components/SbSections/SbSectionHeading';
import SbSectionListLinks from '@/components/SbSections/SbSectionListLinks';
import SbSectionFaq from '@/components/SbSections/SbSectionFaq';

export default {
  components: {
    SbLoading,
    SbSectionRow,
    SbSectionSpacer,
    SbSectionHeading,
    SbSectionListLinks,
    SbSectionFaq,
  },
  computed: {
    sections() {
      return this.getArticleById ? this.getArticleById.sections : [];
    },
    title() {
      return this.getArticleById ? this.getArticleById.title : '';
    },
  },
  apollo: {
    getArticleById: {
      query: GetArticleById,
      variables() {
        return { id: this.$route.params.route.replace('article-', '') };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_view_article {
  padding: 0 1rem;
}
</style>
