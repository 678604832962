<template>
  <div class="sb_section_heading">
    <component
      :is="section.content.titleType"
      class="sb_section_heading_title"
      >{{ section.content.title }}</component
    >
    <div class="sb_section_heading_subtitle">
      {{ section.content.subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbSectionHeading',
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.sb_section_heading {
  margin: 0 0 1rem 0;
  &_title {
  }
  &_subtitle {
    font-size: 1.6rem;
  }
}
</style>
