<template>
  <div
    class="sb_section_spacer"
    :class="'v_height_' + section.content.height"
  ></div>
</template>

<script>
export default {
  name: 'SbSectionSpacer',
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.sb_section_spacer {
  //
  // HEIGHT VARIATIONS
  //
  $heights: 10, 20, 40, 60, 80, 100, 140, 180, 220, 240, 280, 320;

  @each $item in $heights {
    &.v_height_#{$item} {
      height: rem-calc(math.div($item, 2));
      @include breakpoint($medium-portrait) {
        height: rem-calc($item);
      }
    }
  }
}
</style>
