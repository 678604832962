<template>
  <div>
    <sb-spacer height="60" />
    <sb-title text="Kaartenbak" />
    <sb-spacer height="20" />

    <template v-if="isSuggestionsEnabledForMe">
      <sb-cards-suggestions
        :cards="cards"
        @card-select="handleSuggestionSelect"
      />
    </template>

    <sb-cards-list
      v-if="$user.role === 'STUDENT'"
      ref="cardsList"
      v-model="selectedCard"
      :student-ids="[$user.id]"
      :locked-ids="[]"
      :show-exercise-status-enabled="true"
      @cards-change="setCards"
    />
    <sb-cards-list v-else @cards-change="setCards" />
    <sb-spacer height="400" />

    <sb-bottom-fixed-buttons
      v-if="$user.role === 'STUDENT'"
      class="bottom-bar"
      :with-side-menu-indent="false"
    >
      <template #right>
        <i-button
          :disabled="!selectedCard"
          type="primary"
          size="large"
          @click="handleStart"
        >
          Start oefening
        </i-button>
      </template>
    </sb-bottom-fixed-buttons>
  </div>
</template>

<script>
import SbCardsList from '@/components/SbCardsList.vue';
import SbBottomFixedButtons from '@/components/SbBottomFixedButtons';
import SbCardsSuggestions from '@/views/Default/SessionCards/SbCardsSuggestions.vue';
import { useStudentSuggestions } from '@/graphql/hooks/useStudentSuggestions';

export default {
  name: 'Cards',
  components: { SbCardsList, SbCardsSuggestions, SbBottomFixedButtons },
  mixins: [useStudentSuggestions],

  data() {
    return {
      selectedCard: undefined,
      cards: [],
    };
  },

  provide() {
    const self = this;
    return {
      getParentView: () => self,
      students: this.students,
    };
  },

  methods: {
    handleStart() {
      this.$refs.cardsList.startExercise(this.selectedCard);
    },

    handleSuggestionSelect(cardId) {
      // this.selectedCard = cardId;
      this.$refs.cardsList.startExercise(cardId);
    },

    setCards(cards) {
      this.cards = cards;
    },
  },
};
</script>
