<template>
  <div class="sb_view_order">
    <sb-logo-full class="sb_view_order_logo" />
    <div class="sb_view_order_content">
      <div class="sb_view_order_content_top">
        <div class="sb_view_order_content_top_heading">
          <div class="sb_view_order_content_top_heading_left">
            <h1>Niet toegestaan</h1>
          </div>
          <div class="sb_view_order_content_top_heading_right"></div>
        </div>

        <sb-spacer height="20" />
        <p>
          Het lijkt er op dat je een pagina wilt bezoeken waar je geen rechten
          toe hebt.
        </p>
      </div>
      <div class="sb_view_order_content_bottom">
        <div class="sb_view_order_content_bottom_left">
          <!-- IF USER IS LOGGED IN -->
          <router-link :to="{ name: 'OrderStep1' }">
            <i-button type="primary" size="large" @click="$router.go(-1)">
              Terug
            </i-button>
          </router-link>
          <!-- END IF -->
          <router-link to="/login">
            <i-button type="text" size="large"> Naar inloggen </i-button>
          </router-link>
        </div>
        <div class="sb_view_order_content_bottom_right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SbLogoFull from '@/components/SbLogoFull';

export default {
  name: 'Order',
  components: {
    SbLogoFull,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.sb_view_order {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $brand-lightest-gray;
  min-height: 100vh;
  background-image: url('/static/img/bg/leesetende-plant.jpg');
  background-repeat: no-repeat;
  background-position: bottom -10px left 20%;

  .ivu-form-item {
    padding: 0 !important;
  }

  &_logo {
    padding: 100px 0 40px 0;

    @media screen and (min-height: 800px) {
      padding: 14vh 0 40px 0;
    }
  }

  &_content {
    background: $brand-white;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba($brand-black, 0.1),
      0 5px 5px rgba($brand-black, 0.1);
    width: 100%;
    max-width: 900px;

    p {
      max-width: 600px;
      font-size: 18px;
    }

    &_top {
      padding: 40px;
      &_heading {
        padding: 0 0 20px 0;
        margin: 0 0 20px 0;
        border-bottom: solid 1px $brand-light-gray;

        @media screen and (min-width: 1000px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &_left {
        }
        &_right {
          color: $brand-gray;
          padding: 20px 0 0 0;
          @media screen and (min-width: 1000px) {
            padding: 0 0 0 20px;
          }
        }
      }
    }
    &_bottom {
      padding: 30px 40px;
      background: $brand-lightest-gray;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      @media screen and (min-width: 1000px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &_left {
      }
      &_right {
        line-height: 1.2;
        padding: 20px 0 0 0;
        @media screen and (min-width: 1000px) {
          padding: 0 0 0 20px;
          text-align: right;
        }
      }

      p {
        color: $brand-gray;
      }
    }
  }
}
</style>
