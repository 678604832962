<template>
  <div class="sb_section_list-links">
    <component
      :is="section.content.titleType"
      class="sb_section_list-links_title"
      >{{ section.content.title }}</component
    >
    <div v-if="section.content.items" class="sb_section_list-links_items">
      <a
        v-for="(item, i) in section.content.items"
        :key="`item-${i}`"
        :href="item.type == 'file' ? item.file : item.url"
        target="_blank"
        class="sb_section_list-links_item"
      >
        <div class="sb_section_list-links_item_icon">
          <sb-icon icon-id="icon_arrow-download" />
        </div>
        <div class="sb_section_list-links_item_title">{{ item.title }}</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbSectionListLinks',
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.sb_section_list-links {
  width: 100%;
  max-width: 840px;
  margin: 0 0 2rem 0;

  //
  // TITLE
  //
  &_title {
    margin: 0 0 0.4em 0;
  }

  //
  // ITEMS
  //
  &_items {
    border-top: solid 1px $brand-light-gray;
  }

  //
  //  ITEM
  //
  &_item {
    display: flex;
    align-items: center;
    border-bottom: solid 1px $brand-light-gray;
    padding: 0.5rem 0;
    color: $brand-black;
    transition: color 0.3s ease, border-color 0.3s ease;

    //  ITEM - ICON
    &_icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 30px;
      color: $brand-primary;
    }
    //  ITEM - TITLE
    &_title {
    }

    // STATE - ITEM - HOVER
    &:hover {
      color: $brand-primary;
      border-bottom: solid 1px $brand-primary;
    }
  }
}
</style>
