<script>
import { platformSettingsMixin } from '@/mixins/platformSettingsMixin';
import gql from 'graphql-tag';
import SbDivider from './SbDivider.vue';

export default {
  components: { SbDivider },

  mixins: [platformSettingsMixin],

  data() {
    return {
      formData: {
        dictionary: false,
        readingComprehension: false,
      },
    };
  },

  watch: {
    platformSettings: {
      deep: true,
      handler(newValue) {
        if (!newValue) return;
        this.formData.dictionary = newValue.dictionary;
        this.formData.readingComprehension = newValue.readingComprehension;
      },
    },

    formData: {
      deep: true,
      async handler(value) {
        try {
          await this.$apollo.mutate({
            variables: { input: value },
            mutation: gql`
              mutation SbPlatformSettingsForm_SetPlatformSettings(
                $input: SetPlatformSettingsInput!
              ) {
                setPlatformSettings(input: $input) {
                  dictionary
                  readingComprehension
                }
              }
            `,
          });

          this.$Message.success('Instellingen opgeslagen');
        } catch (error) {
          console.error(error);
          this.$showGenericError();
        }
      },
    },
  },
};
</script>

<template>
  <div>
    <sb-divider title="Feature beheer" />
    <i-form>
      <form-item label="Woordenboek">
        <i-switch v-model="formData.dictionary">
          <span slot="open">Aan</span>
          <span slot="close">Uit</span>
        </i-switch>
      </form-item>

      <form-item label="Begrijpend lezen">
        <i-switch v-model="formData.readingComprehension">
          <span slot="open">Aan</span>
          <span slot="close">Uit</span>
        </i-switch>
      </form-item>
    </i-form>
  </div>
</template>

<style scoped lang="scss"></style>
