import GetSessionMe from '@/graphql/queries/GetSessionMe.gql';

export const useStudentSuggestions = {
  data() {
    return {
      isSuggestionsEnabledForMe: false,
      students: [],
    };
  },

  apollo: {
    getEdgeUser: {
      fetchPolicy: 'network-only',
      query: GetSessionMe,

      update(data) {
        // update array in this way because vue loses reactivity via provider if we just reassign this.students...
        // clear original array
        this.students.splice(0, this.students.length);

        // use `push` to append new entries because `<array>.push` is reactive
        this.students.push(data.me);

        this.isSuggestionsEnabledForMe = data.me.cardSuggestionsEnabled;
      },
    },
  },
};
