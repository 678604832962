<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Instellingen</h1>
      </template>
    </sb-page-header>

    <template v-if="$user.role === 'ADMIN'">
      <sb-platform-settings-form />
    </template>

    <template v-else-if="$user.context.resourceId === 'default'">
      Binnenkort beschikbaar.
    </template>

    <template v-else>
      <i-form ref="formSettings" :label-width="160">
        <sb-divider title="Toets" />

        <form-item label="Standaard Toets-type" prop="defaultIntakeType">
          <i-select
            v-model="defaultIntakeType"
            style="max-width: 300px"
            placeholder="Standaard Toets-type"
            :clearable="true"
            @on-change="updateSettings"
          >
            <i-option v-for="item in IntakeType" :key="item" :value="item">
              {{ getIntakeTypeLabel(item) }}
            </i-option>
          </i-select>
        </form-item>

        <sb-divider title="Zelfredzaamheid" />

        <form-item label="Standaard soort lezer" prop="defaultZelfredzaamheid">
          <sb-zelfredzaamheid-select
            v-model="defaultZelfredzaamheid"
            style="max-width: 300px"
            :clearable="true"
            @on-change="updateSettings"
          />
        </form-item>

        <sb-divider title="Sessies" />

        <form-item label="Toon voortgang en sessies aan ouders">
          <sb-info>
            <p>
              Toon de voortgang van oefeningen en sessies met de begeleider in
              de ouder/leering omgeving.
            </p>
          </sb-info>
          <i-switch v-model="showCoachSessions" @on-change="updateSettings">
            <span slot="open">Ja</span>
            <span slot="close">Nee</span>
          </i-switch>
        </form-item>

        <sb-divider title="Leerling" />
        <form-item label="Kaart suggesties tonen">
          <sb-info>
            <p>
              Toon de voortgang van oefeningen en sessies met de begeleider in
              de ouder/leering omgeving.
            </p>
          </sb-info>
          <i-switch
            v-model="cardSuggestionsEnabled"
            @on-change="updateSettings"
          >
            <span slot="open">Ja</span>
            <span slot="close">Nee</span>
          </i-switch>
        </form-item>
      </i-form>
    </template>
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider';
import SbPageHeader from '@/components/SbPageHeader';
import { intakeTypeMixin } from '@/lib/intake-type';
import gql from 'graphql-tag';
import SbZelfredzaamheidSelect from '@/components/SbZelfredzaamheidSelect.vue';
import SbPlatformSettingsForm from '../../components/SbPlatformSettingsForm.vue';

export default {
  components: {
    SbDivider,
    SbPageHeader,
    SbZelfredzaamheidSelect,
    SbPlatformSettingsForm,
  },

  mixins: [intakeTypeMixin],

  data() {
    return {
      defaultIntakeType: undefined,
      defaultZelfredzaamheid: undefined,
      showCoachSessions: undefined,
      cardSuggestionsEnabled: undefined,
    };
  },

  methods: {
    async updateSettings() {
      const {
        defaultIntakeType,
        defaultZelfredzaamheid,
        showCoachSessions,
        cardSuggestionsEnabled,
      } = this;
      try {
        const id = this.$route.params.resourceId;
        const { errors } = await this.$apollo.mutate({
          mutation: gql`
            mutation UpdateDefaultIntakeType($input: UpdateOrganisationInput!) {
              updateOrganisation(input: $input) {
                id
                ... on School {
                  defaultIntakeType
                  showCoachSessions
                  cardSuggestionsEnabled
                }
              }
            }
          `,
          variables: {
            input: {
              id,
              data: {
                defaultIntakeType: defaultIntakeType || null,
                defaultZelfredzaamheid: defaultZelfredzaamheid || null,
                showCoachSessions,
                cardSuggestionsEnabled,
              },
            },
          },
        });
        if (errors) {
          return this.$showGenericError(
            undefined,
            errors.map((e) => e.message).join(', '),
          );
        }

        this.$Message.success('Wijzigingen opgeslagen');
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },
  },

  apollo: {
    settings: {
      query() {
        return gql`
          query SchoolSettings {
            getOrganisationById(id: "${this.$route.params.resourceId}") {
              id
              ... on School {
                defaultIntakeType
                defaultZelfredzaamheid
                showCoachSessions
                cardSuggestionsEnabled
                renewalPolicy
                startDate
              }
            }
          }
        `;
      },

      skip() {
        return !(
          this.$route.params.resourceType === 'school' &&
          !!this.$route.params.resourceId
        );
      },

      update(data) {
        this.showCoachSessions = data.getOrganisationById?.showCoachSessions;
        this.defaultIntakeType = data.getOrganisationById?.defaultIntakeType;
        this.defaultZelfredzaamheid =
          data.getOrganisationById?.defaultZelfredzaamheid;
        this.cardSuggestionsEnabled =
          data.getOrganisationById?.cardSuggestionsEnabled;
      },
    },
  },
};
</script>

<style></style>
