<template>
  <div class="sb_section_row">
    <!-- {{ section }} -->
    <div
      class="sb_section_row_container"
      :class="getColumnClasses(section.content.columns)"
    >
      <div
        v-for="(item, i) in section.content.columns"
        :key="`column-${i}`"
        class="sb_section_row_column"
      >
        <div
          v-if="item.type == 'image' && item.image"
          class="sb_section_row_column_image"
        >
          <img :srcSet="item.image" />
        </div>
        <div
          v-if="item.type == 'youtube' && item.youtube"
          class="sb_section_row_column_video-embed"
        >
          {{ item.youtube }}
          <iframe
            frameborder="0"
            scrolling="no"
            width="960"
            height="540"
            type="text/html"
            :src="getEmbedUrl(item.youtube)"
          />
        </div>
        <div
          v-if="item.type == 'content'"
          class="sb_section_row_column_content"
          v-html="item.content"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbSectionRow',
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getColumnClasses(columns) {
      return `v_col-amount_${columns.length}`;
    },
    getYoutubeId(url) {
      const youtubeRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(youtubeRegex);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getVimeoId(url) {
      const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
      const match = url.match(vimeoRegex);

      return match && match[1] ? match[1] : null;
    },
    getEmbedUrl(url) {
      if (url.includes('youtube')) {
        const id = this.getYoutubeId(url);
        return `//www.youtube.com/embed/${id}`;
      }
      if (url.includes('vimeo')) {
        const id = this.getVimeoId(url);
        return `//player.vimeo.com/video/${id}`;
      }
    },
  },
};
</script>

<style lang="scss">
.sb_section_row {
  width: 100%;
  max-width: 840px;
  margin: 0;

  //
  //  CONTAINER
  //
  &_container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 -1rem;

    //
    // CONTAINER - COL AMOUNTS
    //
    &.v_col-amount_1 {
    }
    &.v_col-amount_2 {
      @include breakpoint(960) {
        grid-template-columns: 1fr 1fr;
      }
    }
    &.v_col-amount_3 {
      @include breakpoint(960) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    &.v_col-amount_4 {
      @include breakpoint(960) {
        grid-template-columns: 1fr 1fr;
      }
      @include breakpoint($large) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    //
    // CONTAINER - MAX_WIDTHS
    //
    $max-widths: 600, 800, 1000, 1200, 1400, 1600;

    @each $item in $max-widths {
      &.v_max-width_#{$item} {
        max-width: rem-calc($item);
      }
    }
  }

  //
  //  COLUMN
  //
  &_column {
    padding: 0 1rem;
    margin: 0 0 1rem 0;

    //  COLUMN - CONTENT
    &_content {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0 0 0.2em 0;
      }
    }

    //  COLUMN - IMAGE
    &_image {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    //  COLUMN - VIDEO-EMBED
    &_video-embed {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
