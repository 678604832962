<template>
  <sb-techniques-list :sync-with-query-param="true" />
</template>

<script>
import SbTechniquesList from '@/components/SbTechniquesList.vue';

export default {
  components: { SbTechniquesList },
};
</script>

<style lang="scss"></style>
