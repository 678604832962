<template>
  <div class="sb_section_faq">
    <div v-if="section.content.categories" class="sb_section_faq_anchors">
      <button
        v-for="(category, i) in section.content.categories"
        :key="`anchor-${i}`"
        v-scroll-to="getScrollTo(i)"
        class="sb_section_faq_anchor"
      >
        <div class="sb_section_faq_anchor_icon">
          <sb-icon icon-id="icon_double-caret-down" />
        </div>
        <div class="sb_section_faq_anchor_title">
          {{ category.categoryName }}
        </div>
      </button>
    </div>
    <div v-if="section.content.categories" class="sb_section_faq_categories">
      <div
        v-for="(category, i) in section.content.categories"
        :id="`category-${i}`"
        :key="`category-${i}`"
        class="sb_section_faq_category"
      >
        <h2 class="sb_section_faq_category_title">
          {{ category.categoryName }}
        </h2>
        <div
          v-for="(item, index) in category.items"
          :key="`item-${index}`"
          class="sb_section_faq_category_item"
        >
          <div class="sb_section_faq_category_item_top" @click="toggleCategory">
            <div class="sb_section_faq_category_item_top_left">
              <div class="sb_section_faq_category_item_title">
                {{ item.title }}
              </div>
            </div>
            <div class="sb_section_faq_category_item_top_right">
              <div class="sb_section_faq_category_item_top_icon">
                <sb-icon icon-id="icon_caret-down" />
              </div>
            </div>
          </div>

          <div class="sb_section_faq_category_item_content">
            <div
              class="sb_section_faq_category_item_content_inner"
              v-html="item.content"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbSectionListLinks',
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toggleCategory: function (event) {
      event.target.classList.toggle('s_is-open');
    },
    getScrollTo(i) {
      return {
        el: `#category-${i}`,
        duration: 500,
        offset: -50,
      };
    },
  },
};
</script>

<style lang="scss">
.sb_section_faq {
  width: 100%;
  max-width: 840px;
  margin: 0 0 2rem 0;

  //
  // TITLE
  //
  &_title {
    margin: 0 0 0.4em 0;
  }

  //
  // ANCHORS
  //
  &_anchors {
    margin: 0 0 3rem 0;
    border-top: solid 1px $brand-light-gray;
  }

  //
  //  ANCHOR
  //
  &_anchor {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid 1px $brand-light-gray;
    padding: 0.5rem 0;
    color: $brand-black;
    transition: color 0.3s ease, border-color 0.3s ease;
    user-select: none;

    //  ANCHOR - ICON
    &_icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 30px;
      color: $brand-primary;
      transition: transform 0.3s ease;
    }
    //  ANCHOR - TITLE
    &_title {
    }

    // STATE - ANCHOR - HOVER
    &:hover {
      color: $brand-primary;
      border-bottom: solid 1px $brand-primary;

      .sb_section_faq_anchor_icon {
        transform: translateY(3px);
      }
    }
  }

  //
  //  CATEGORIES
  //
  &_categories {
  }

  //
  //  CATEGORY
  //
  &_category {
    margin: 0 0 3rem 0;

    //  CATEGORY - TITLE
    &_title {
      margin: 0 0 0.6em 0;
    }

    //  CATEGORY - ITEM
    &_item {
      position: relative;
      margin: 0 0 5px 0;

      // CATEGORY - ITEM
      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0 1rem 1rem;
        background: $brand-lightest-gray;
        user-select: none;
        border-radius: 0.3rem;

        // CATEGORY - ITEM - TOP - LEFT
        &_left {
          @include fontSizeLarge;
          pointer-events: none;
        }
        // CATEGORY - ITEM - TOP - RIGHT
        &_right {
          pointer-events: none;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          overflow: hidden;
          position: relative;
          flex: 0 0 rem-calc(50px);
          min-width: rem-calc(50px);
        }
        // CATEGORY - ITEM - TOP - ICON
        &_icon {
          margin: 0 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 1.4rem;
          color: $brand-primary;
          transition: transform 0.3s ease;
        }
        // STATE - CATEGORY - ITEM - TOP - HOVER
        &:hover {
          cursor: pointer;
          background-color: $brand-primary-lightest;
        }

        //
        //  STATE - CATEGORY - ITEM - TOP - IS OPEN
        //
        &.s_is-open {
          + .sb_section_faq_category_item_content {
            max-height: 9999px;
            overflow: inherit;
            opacity: 1;
          }

          .sb_section_faq_category_item_top_icon {
            transform: rotate(180deg);
          }
        }
      }

      // CATEGORY - TITLE
      &_title {
      }

      // CATEGORY - CONTENT
      &_content {
        overflow: hidden;
        max-height: 0px;

        &_inner {
          padding: 1rem 1rem 1rem 1rem;
          img {
            max-width: 100%;
            height: auto;
          }
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
