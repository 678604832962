<template>
  <div>
    <sb-loading v-if="$apollo.loading" />
    <modal v-model="showModal" @on-ok="downloadFile">
      Je download is klaar. Druk op OK om te downloaden.
    </modal>

    <sb-page-header>
      <sb-page-header-title> Overig </sb-page-header-title>
    </sb-page-header>
    <tabs :animate="false">
      <tab-pane name="export" label="Exporteren">
        <sb-page-header>
          <sb-page-header-title> Exporteren </sb-page-header-title>
        </sb-page-header>
        <div class="buttons">
          <i-button @click="exportSchools"> Scholen </i-button>
          <i-button @click="exportSchoolDistricts"> Schooldistricten </i-button>
        </div>
      </tab-pane>
    </tabs>
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader.vue';
import SbPageHeaderTitle from '@/components/SbPageHeaderParts/SbPageHeaderTitle.vue';
import gql from 'graphql-tag';
import SbLoading from '@/components/SbLoading.vue';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';

export default {
  name: 'Miscellaneous',
  components: { SbPageHeader, SbPageHeaderTitle, SbLoading },
  mixins: [nonReactiveMembersMixin(() => ({ downloadFileName: undefined }))],
  data() {
    return { download: undefined, showModal: false };
  },

  methods: {
    async exportSchools() {
      this.downloadFileName = `scholen`;
      await this.exportOrganisations({
        organisationType: 'SCHOOL',
      });
    },

    async exportSchoolDistricts() {
      this.downloadFileName = `schooldistricten`;
      await this.exportOrganisations({
        organisationType: 'SCHOOL_DISTRICT',
      });
    },

    async exportOrganisations(filter = {}) {
      try {
        const { data } = await this.$apollo.query({
          fetchPolicy: 'network-only',
          query: gql`
            query Miscellanous_ExportOrganisations {
              exportOrganisations
            }
          `,
          variables: { filter },
        });

        if (data.exportOrganisations) {
          this.download = data.exportOrganisations;
          this.showModal = true;
        } else {
          throw new Error('No export data received');
        }
      } catch (error) {
        this.$showGenericError();
      }
    },

    downloadFile() {
      if (!this.download) return;
      const a = document.createElement('a');
      a.onclick = function () {
        this.remove();
      };
      a.href = this.getXlsxUrl(this.download);
      a.style.display = 'none';
      a.download = this.getFileName(this.downloadFileName);
      document.body.append(a);
      a.click();
    },

    getXlsxUrl(input) {
      const mediaType =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
      const url = mediaType + input;
      return url;
    },

    getFileName(name = 'export', extension = 'xlsx') {
      return `Leeshelden ${name} ${new Date().toLocaleDateString()}.${extension}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: inline-grid;
  gap: 1rem;
}
</style>
